<template>
  <b-card>
    <template v-if="item.yearEndReview !== null">
      <div class="py-2">
        <h5 class="mb-2">
          <span>1. {{ $t("field.hodComment") }}</span>
        </h5>
        <n-text-view
          size="md"
          :value="item.yearEndReview.hodComment"
        ></n-text-view>
      </div>
      <div class="py-2">
        <h5 class="mb-2">
          <span>2. {{ $t("field.gmComment") }}</span>
        </h5>
        <n-text-view
          size="md"
          :value="item.yearEndReview.gmComment"
        ></n-text-view>
      </div>
      <div class="py-2">
        <h5 class="mb-2">
          <span>
            3. {{ $t("field.hrComment") }}
            <span
              v-if="canComment(item.yearEndReview.state)"
              class="text-muted"
              >- {{ $t("field.optional") }}</span
            >
          </span>
        </h5>
        <b-form-textarea
          v-if="canComment(item.yearEndReview.state)"
          class="pb-2"
          rows="3"
          max-rows="6"
          :placeholder="$t('field.hrComment')"
          v-model="data.hrComment"
        ></b-form-textarea>
        <n-text-view
          v-else
          size="md"
          :value="item.yearEndReview.hrComment"
        ></n-text-view>
      </div>
    </template>
    <template v-else>
      <b-img
        class="py-5"
        fluid
        center
        src="@/assets/images/pages/no-data.svg"
        height="220"
        width="220"
        alt="Image"
      ></b-img>
    </template>
  </b-card>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardTitle,
  BCardText,
  BImg,
  BFormTextarea,
} from "bootstrap-vue";
import NTextView from "@/components/NTextView.vue";
import state from "@/data/state";
import { getTranslate, canHrComment } from "@/libs/helper";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardTitle,
    BCardText,
    BImg,
    BFormTextarea,

    NTextView,
  },
  props: {
    item: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  computed: {
    state() {
      return { ...state };
    },
  },
  watch: {
    "data.hrComment": function (newValue, oldValue) {
      this.$emit("comment", newValue);
    },
  },
  data() {
    return {
      data: {
        hrComment: null,
      },
      yearEndReview: {},
      loading: false,

      getTranslate,
      canHrComment,
    };
  },
  created() {},
  methods: {
    canComment(value) {
      return this.state.hrapproved !== value && this.state.managementapproved !== value && this.state.managementrejected !== value;
    },
  },
};
</script>
