<template>
  <b-card-code title="">
    <b-card-text class="mb-3" v-if="objectiveType.descriptionEn">
      <h5>{{ getTranslate(objectiveType, "description", $i18n.locale) }}</h5>
    </b-card-text>

    <okr-setting-header />

    <template
      v-if="data.midYearReview && data.midYearReview.state != state.draft"
    >
      <app-collapse accordion type="margin">
        <app-collapse-item
          :is-visible="true"
          v-for="(item, index) in midYearReview.objectives"
          :key="`${item}-${index}`"
          :title="`${item}-${index}`"
        >
          <template #header>
            <okr-setting-title
              :no="index + 1"
              :title="item.okrSettingObjective.title"
              :weight="item.okrSettingObjective.weight"
            />
          </template>
          <hr />
          <okr-setting-description>
            <template #description>
              <div
                v-for="(result, idx) in item.objectiveKeyResults"
                :key="`${result}-${idx}`"
                v-if="result.title !== null"
              >
                <hr class="my-2" v-show="idx != 0" />
                <h6>
                  <span> #{{ idx + 1 }}. </span>
                  <span>{{ result.title }}</span>
                </h6>
                <label class="mt-1 mr-2">
                  {{ $t("field.employeeReview") }}
                </label>
                <b-badge
                  v-if="result.status"
                  pill
                  :variant="getMidYearStateColor(result.status.nameEn)"
                  badge-glow
                >
                  {{ getTranslate(result.status, "name", $i18n.locale) }}
                </b-badge>
                <n-text-view class="mb-2" :value="result.remark"></n-text-view>
              </div>
            </template>
            <template #footer>
              <h6 class="mb-2">{{ $t("field.directSupervisorReview") }}</h6>
              <n-text-view size="md" :value="item.supervisorComment" />
            </template>
          </okr-setting-description>
        </app-collapse-item>
      </app-collapse>
    </template>

    <template v-else>
      <app-collapse accordion type="margin">
        <app-collapse-item
          :is-visible="true"
          v-for="(objective, idx) in objectives"
          :key="`${objective}-${idx}`"
          :title="`${objective}-${idx}`"
        >
          <template #header>
            <okr-setting-title
              :no="idx + 1"
              :title="objective.title"
              :weight="objective.weight"
            />
          </template>
          <hr />
          <okr-setting-description>
            <template #description>
              <div
                v-for="(result, rIdx) in objective.objectiveKeyResults"
                :key="`${result}-${rIdx}`"
                v-if="result.title !== null"
              >
                <h6>
                  <span> #{{ rIdx + 1 }}. </span>
                  <span>{{ result.title }}</span>
                </h6>
                <label class="mt-1 mr-2">
                  {{ $t("field.employeeReview") }}
                </label>
                <b-badge pill variant="secondary" badge-glow>
                  {{ $t("state.notstarted") }}
                </b-badge>
                <n-text-view class="mb-2"></n-text-view>
                <hr
                  class="my-2"
                  v-show="rIdx < objective.objectiveKeyResults.length - 1"
                />
              </div>
            </template>
            <template #footer>
              <h6 class="mb-2">{{ $t("field.directSupervisorReview") }}</h6>
              <n-text-view size="md" />
            </template>
          </okr-setting-description>
        </app-collapse-item>
      </app-collapse>
    </template>

    <okr-setting-footer :total-weight="totalWeight" />
  </b-card-code>
</template>

<script>
import {
  BCol,
  BRow,
  BCard,
  BFormRadioGroup,
  BFormRadio,
  BFormGroup,
  BCardText,
  BFormInput,
  BButton,
  BFormTextarea,
  BContainer,
  BBadge,
} from "bootstrap-vue";
import BCardCode from "@core/components/b-card-code";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import OkrSettingHeader from "@/components/OkrSettingHeader.vue";
import OkrSettingTitle from "@/components/OkrSettingTitle.vue";
import OkrSettingFooter from "@/components/OkrSettingFooter.vue";
import OkrSettingDescription from "@/components/OkrSettingDescription.vue";
import NTextView from "@/components/NTextView.vue";
import NTextareaInput from "@/components/NTextareaInput.vue";
import NAsyncSingleSelect from "@/components/NAsyncSingleSelect";
import Repository from "@/repositories/RepositoryFactory";
import { getTranslate, getMidYearStateColor } from "@/libs/helper";
import state from "@/data/state";

const OkrSettingRepository = Repository.get("okrSetting");

export default {
  components: {
    BFormInput,
    BCard,
    BCol,
    BRow,
    BFormRadioGroup,
    BFormRadio,
    BCardText,
    BFormGroup,
    BButton,
    BFormTextarea,
    BContainer,
    BBadge,

    NAsyncSingleSelect,
    BCardCode,
    AppCollapse,
    AppCollapseItem,
    OkrSettingHeader,
    OkrSettingFooter,
    OkrSettingTitle,
    OkrSettingDescription,
    NTextView,
    NTextareaInput,
  },
  props: {
    objectiveType: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  computed: {
    state() {
      return { ...state };
    },
  },
  data() {
    return {
      data: {},
      objectives: [],
      midYearReview: {},
      totalWeight: 0,
      loading: false,

      getTranslate,
      getMidYearStateColor,
    };
  },
  created() {
    this.show();
  },
  methods: {
    show() {
      this.loading = true;
      const typeId = this.objectiveType.id ?? null;
      const params = `${this.$route.params.id}?objective_type_id=${typeId}`;
      OkrSettingRepository.show(params).then((response) => {
        const data = response?.data?.data;
        if (data) {
          this.data = { ...data };
          this.objectives = [...data?.objectives];
          this.midYearReview = { ...data?.midYearReview };

          this.objectives.forEach((objective) => {
            this.totalWeight += objective.weight;
          });
        }
      });
    },
  },
};
</script>
