<template>
  <b-card>
    <template v-if="data.yearEndReview !== null && items.length > 0">
      <div class="py-2" v-for="(item, index) in items">
        <h5 class="mb-2">
          <span>{{ index + 1 }}. </span>
          <span>{{ getTranslate(item.question, "title", $i18n.locale) }}</span>
        </h5>
        <n-text-view size="md" :value="item.comment"></n-text-view>
      </div>
    </template>
    <template v-else>
      <b-img
        class="py-5"
        fluid
        center
        src="@/assets/images/pages/no-data.svg"
        height="220"
        width="220"
        alt="Image"
      ></b-img>
    </template>
  </b-card>
</template>

<script>
import { BRow, BCol, BCard, BCardTitle, BCardText, BImg } from "bootstrap-vue";
import NTextView from "@/components/NTextView.vue";
import { getTranslate } from "@/libs/helper";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardTitle,
    BCardText,
    BImg,

    NTextView,
  },
  props: {
    data: {
      type: Object,
      default: function () {
        return {};
      },
    },
    items: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  computed: {},
  data() {
    return {
      getTranslate,
    };
  },
  created() {},
  methods: {},
};
</script>
