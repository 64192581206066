<template>
  <b-button
    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
    variant="danger"
    class="mt-1 mr-1"
    right
    split
    @click="reject()"
    :disabled="loading"
    v-bind="$attrs"
  >
    <b-spinner small label="Spinning" v-if="loading"></b-spinner>
    {{ $t(label) }}

    <b-modal
      ref="refModal"
      :title="$t('alert.areYouSureWantToReject')"
      :okTitle="$t('button.continue')"
      cancel-variant="outline-secondary"
      centered
      size="lg"
      @show="reset"
      @hidden="reset"
      @ok="handleOk"
    >
      <validation-observer ref="createForm" #default="{ invalid }">
        <n-form-confirmation
          key="createForm"
          @submit="submit"
          :form="$refs.createForm"
          :disabled="invalid"
          class="pt-1 pb-2"
        >
          <validation-provider
            #default="{ errors }"
            :vid="reason"
            :name="$t('field.rejectReason')"
            rules="required"
          >
            <label for="rejectReason">{{ $t("field.rejectReason") }}</label>
            <b-form-textarea
              rows="4"
              max-rows="6"
              v-model="reason"
              :class="errors.length > 0 ? 'is-invalid' : null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </n-form-confirmation>
      </validation-observer>
    </b-modal>
  </b-button>
</template>

<script>
import { BSpinner, BButton, BModal, BFormTextarea } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import NFormConfirmation from "@/components/NFormConfirmation";

export default {
  components: {
    BSpinner,
    BButton,
    BModal,
    BFormTextarea,
    NFormConfirmation,
  },
  directives: {
    Ripple,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "button.reject",
    },
    route: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "reject",
    },
  },
  data() {
    return {
      reason: null,
    };
  },
  mounted() {},
  methods: {
    reject() {
      this.$refs.refModal.show();
    },
    reset() {
      this.reason = null;
    },
    handleOk(bvModalEvent) {
      bvModalEvent.preventDefault();
      this.$refs.createForm.validate().then((success) => {
        if (success) {
          this.submit(this.type);
        }
      });
    },
    submit(type) {
      this.$emit("reason", this.reason);
      this.$emit("save", type);
      this.$refs.refModal.hide();
    },
    afterSave() {
      this.$router.push({
        name: `list-${this.route}`,
      });
    },
  },
};
</script>
