<template>
  <b-card-code title="">
    <load-profile></load-profile>
    <b-card-text class="mb-3" v-if="objectiveType.descriptionEn">
      <h5>{{ getTranslate(objectiveType, "description", $i18n.locale) }}</h5>
    </b-card-text>

    <okr-setting-header />

    <app-collapse accordion type="margin">
      <app-collapse-item
        :is-visible="true"
        :title="`${item}-${index}`"
        v-for="(item, index) in objectives"
        :key="`${item}-${index}`"
      >
        <template #header>
          <okr-setting-title
            :no="index + 1"
            :title="item.title"
            :weight="item.weight"
          />
        </template>
        <hr />
        <okr-setting-description>
          <template #description>
            <n-text-view
              v-for="(result, idx) in item.objectiveKeyResults"
              :key="`${result}-${idx}`"
              class="pb-1"
              :value="result.title"
            ></n-text-view>
          </template>
        </okr-setting-description>
      </app-collapse-item>
    </app-collapse>

    <okr-setting-footer :total-weight="totalWeight" />

    <b-row class="pt-1">
      <b-col cols="12" class="text-right">
        <template
          v-if="
            tabIndex == objectiveTypeLength - 1 &&
            (hasGm ? canHrReview(data.state) : canHrReviewWithoutGm(data.state))
          "
        >
          <n-button-reject
            ref="btnReject"
            @submit="submit('reject')"
            @save="save"
            @reason="rejectRemark"
            :loading="loading"
            :route="route"
            v-if="$can('create', resource)"
          ></n-button-reject>
          <n-button-save
            ref="btnApprove"
            label="button.approve"
            variant="success"
            @submit="submit('approve')"
            @save="save"
            :loading="loading"
            :route="route"
            v-if="$can('create', resource)"
          ></n-button-save>
        </template>
        <n-button-loading
          type="button"
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          variant="outline-secondary"
          :loading="loading"
          class="mt-1"
          @submit="back"
        >
          {{ $t("button.back") }}
        </n-button-loading>
      </b-col>
    </b-row>
  </b-card-code>
</template>

<script>
import {
  BCol,
  BRow,
  BCard,
  BFormRadioGroup,
  BFormRadio,
  BFormGroup,
  BCardText,
  BFormInput,
  BButton,
  BFormTextarea,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import BCardCode from "@core/components/b-card-code";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import OkrSettingHeader from "@/components/OkrSettingHeader.vue";
import OkrSettingTitle from "@/components/OkrSettingTitle.vue";
import OkrSettingFooter from "@/components/OkrSettingFooter.vue";
import OkrSettingDescription from "@/components/OkrSettingDescription.vue";
import NButtonSave from "@/components/NButtonSave.vue";
import NButtonReject from "@/components/NButtonReject.vue";
import NButtonLoading from "@/components/NButtonLoading";
import NTextView from "@/components/NTextView.vue";
import Repository from "@/repositories/RepositoryFactory";
import { getTranslate, canHrReview, canHrReviewWithoutGm } from "@/libs/helper";
import state from "@/data/state";
import moment from "moment";
import Swal from "sweetalert2";

const OkrSettingRepository = Repository.get("okrSetting");

export default {
  components: {
    BFormInput,
    BCard,
    BCol,
    BRow,
    BFormRadioGroup,
    BFormRadio,
    BCardText,
    BFormGroup,
    BButton,
    BFormTextarea,

    BCardCode,
    AppCollapse,
    AppCollapseItem,
    OkrSettingHeader,
    OkrSettingFooter,
    OkrSettingTitle,
    OkrSettingDescription,
    NTextView,
    NButtonSave,
    NButtonReject,
    NButtonLoading,
  },
  directives: {
    Ripple,
  },
  props: {
    objectiveType: {
      type: Object,
      default: function () {
        return {};
      },
    },
    objectiveTypeLength: {},
    tabIndex: {},
  },
  computed: {
    state() {
      return { ...state };
    },
  },
  data() {
    return {
      data: {
        hrRejectRemark: null,
        hrRejectBy: null,
        hrApproveBy: null,
        hrRejectAt: null,
        hrApproveAt: null,
      },
      objectives: [],
      totalWeight: 0,
      hasGm: true,
      loading: false,

      getTranslate,
      canHrReview,
      canHrReviewWithoutGm,
    };
  },
  created() {
    this.show();
  },
  methods: {
    show() {
      this.loading = true;
      const typeId = this.objectiveType.id ?? null;
      const params = `${this.$route.params.id}?objective_type_id=${typeId}`;
      OkrSettingRepository.show(params).then((response) => {
        const data = response?.data?.data;
        if (data) {
          this.data = { ...data };
          this.objectives = [...data?.objectives];
          this.hasGm = this.data.hodApprovedBy.managerId !== null;

          this.objectives.forEach((objective) => {
            this.totalWeight += objective.weight;
          });
        }
        this.loading = false;
      });
    },
    submit(type) {
      if (type == "approve") {
        this.$refs.btnApprove.confirm("approve");
      } else {
        this.$refs.btnReject.confirm("reject");
      }
    },
    rejectRemark(value) {
      this.data.hrRejectRemark = value;
    },
    save(type) {
      this.loading = true;

      if (type == "approve") {
        this.data.state = state.hrapproved;
        this.data.hrApproveBy = this.$store.state.profile.id;
        this.data.hrApproveAt = moment().format();
      } else {
        this.data.state = state.hrrejected;
        this.data.hrRejectBy = this.$store.state.profile.id;
        this.data.hrRejectAt = moment().format();
      }

      OkrSettingRepository.updateState(this.$route.params.id, this.data)
        .then((response) => {
          this.back();
        })
        .catch((error) => {
          if (error.response?.status == 422) {
            var message = error.response?.data?.message;

            Swal.fire({
              title: this.$t("error.invalid"),
              icon: "warning",
              html: message,
              customClass: {
                confirmButton: "btn btn-primary",
                title: "mb-2",
                content: "p-0",
              },
              buttonsStyling: false,
            });
          }
        })
        .then(() => {
          this.loading = false;
        });
    },
    back() {
      this.$router.push({
        name: `list-${this.route}`,
      });
    },
  },
  setup() {
    const resource = "okr-setting";
    const route = "okr-setting";

    return { resource, route };
  },
};
</script>
