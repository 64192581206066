<template>
  <div>
    <b-overlay :show="loading" variant="light">
      <b-card>
        <div v-if="data.employeeId">
          <b-row>
            <b-col>
              <b-card-title title-tag="h2" class="font-weight-bold mb-1">
                {{ $t("breadcrumb.employeeInformation") }}
              </b-card-title>
            </b-col>
            <b-col class="text-right" v-show="data.yearEndReview !== null">
              <template v-if="canExport()">
                <n-button-loading
                  class="mr-1"
                  type="button"
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="info"
                  :loading="loading"
                  @submit="exportExcel"
                >
                  {{ $t("button.exportExcel") }}
                </n-button-loading>
                <n-button-loading
                  type="button"
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="success"
                  :loading="loading"
                  @submit="exportPdf"
                >
                  {{ $t("button.exportPdf") }}
                </n-button-loading>
              </template>
            </b-col>
          </b-row>

          <n-image-view :image="data.employee.profile" />

          <b-row class="mt-2">
            <b-col cols="12" md="6">
              <b-row>
                <b-col cols="auto" lg="5">
                  <feather-icon class="mr-1" icon="UserIcon" />
                  <span>{{ $t("field.employeeName") }} :</span>
                </b-col>
                <b-col v-if="data.employee.name">
                  <h5>{{ data.employee.name }}</h5>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="12" md="6">
              <b-row>
                <b-col cols="auto" lg="5">
                  <feather-icon class="mr-1" icon="CreditCardIcon" />
                  <span>{{ $t("field.employeeId") }} :</span>
                </b-col>
                <b-col v-if="data.employee.idCard">
                  <h5>{{ data.employee.idCard }}</h5>
                </b-col>
              </b-row>
            </b-col>
          </b-row>

          <b-row class="my-1">
            <b-col cols="12" md="6">
              <b-row>
                <b-col cols="auto" lg="5">
                  <feather-icon class="mr-1" icon="GitCommitIcon" />
                  <span>{{ $t("field.position") }} :</span>
                </b-col>
                <b-col v-if="data.employee.position">
                  <h5>{{ data.employee.position.name }}</h5>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="12" md="6">
              <b-row>
                <b-col cols="auto" lg="5">
                  <feather-icon class="mr-1" icon="GitBranchIcon" />
                  <span>{{ $t("field.department") }} :</span>
                </b-col>
                <b-col v-if="data.employee.department">
                  <h5>{{ data.employee.department.name }}</h5>
                </b-col>
              </b-row>
            </b-col>
          </b-row>

          <b-row class="my-1">
            <b-col cols="12" md="6">
              <b-row>
                <b-col cols="auto" lg="5">
                  <feather-icon class="mr-1" icon="UserCheckIcon" />
                  <span>{{ $t("field.directSupervisor") }} :</span>
                </b-col>
                <b-col v-if="data.employee.manager">
                  <h5>{{ data.employee.manager.name }}</h5>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="12" md="6">
              <b-row>
                <b-col cols="auto" lg="5">
                  <feather-icon class="mr-1" icon="CalendarIcon" />
                  <span>{{ $t("field.year") }} :</span>
                </b-col>
                <b-col v-if="data.year">
                  <h5>{{ data.year }}</h5>
                </b-col>
              </b-row>
            </b-col>
          </b-row>

          <template>
            <b-card-title title-tag="h2" class="font-weight-bold pt-1">
              {{ $t("breadcrumb.rejectReason") }}
            </b-card-title>
            <b-row class="pb-1">
              <b-col cols="12" md="4">
                <b-row>
                  <b-col cols="8" md="12" lg="6">
                    <span>{{ $t("breadcrumb.okrSetting") }} : </span>
                  </b-col>
                  <b-col cols="4" md="12" lg="6">
                    <b-link
                      class="link-underline text-danger"
                      @click="openModal('okrsetting')"
                      v-if="
                        data.rejectRemark !== null ||
                        data.hodRejectRemark !== null ||
                        data.gmRejectRemark !== null ||
                        data.hrRejectRemark !== null
                      "
                    >
                      {{ $t("button.viewDetail") }}
                    </b-link>
                    <span class="text-grey" v-else> N/A </span>
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="12" md="4">
                <b-row>
                  <b-col cols="8" md="12" lg="6">
                    <span>{{ $t("breadcrumb.midYearReview") }} : </span>
                  </b-col>
                  <b-col cols="4" md="12" lg="6">
                    <template v-if="data.midYearReview !== null">
                      <b-link
                        class="link-underline text-danger"
                        @click="openModal('midyearreview')"
                        v-if="
                          data.midYearReview.rejectRemark !== null ||
                          data.midYearReview.hodRejectRemark !== null ||
                          data.midYearReview.gmRejectRemark !== null ||
                          data.midYearReview.hrRejectRemark !== null
                        "
                      >
                        {{ $t("button.viewDetail") }}
                      </b-link>
                      <span class="text-grey" v-else> N/A </span>
                    </template>
                    <span class="text-grey" v-else> N/A </span>
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="12" md="4">
                <b-row>
                  <b-col cols="8" md="12" lg="6">
                    <span>{{ $t("breadcrumb.yearEndReview") }} : </span>
                  </b-col>
                  <b-col cols="4" md="12" lg="6">
                    <template v-if="data.yearEndReview !== null">
                      <b-link
                        class="link-underline text-danger"
                        @click="openModal('yearendreview')"
                        v-if="
                          data.yearEndReview.rejectRemark !== null ||
                          data.yearEndReview.hodRejectRemark !== null ||
                          data.yearEndReview.gmRejectRemark !== null ||
                          data.yearEndReview.hrRejectRemark !== null
                        "
                      >
                        {{ $t("button.viewDetail") }}
                      </b-link>
                      <span class="text-grey" v-else> N/A </span>
                    </template>
                    <span class="text-grey" v-else> N/A </span>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </template>
        </div>
      </b-card>

      <b-tabs pills class="tab-card" v-model="tabIndex">
        <b-tab active>
          <template #title>
            <feather-icon icon="FileIcon" />
            <span>{{ $t("breadcrumb.okrSetting") }}</span>
          </template>
          <okr-setting-review></okr-setting-review>
        </b-tab>
        <b-tab :disabled="isDisabledMidYearReview()">
          <template #title>
            <feather-icon icon="FileMinusIcon" />
            <span>{{ $t("breadcrumb.midYearReview") }}</span>
          </template>
          <mid-year-review></mid-year-review>
        </b-tab>
        <b-tab :disabled="isDisabledYearEndReview()">
          <template #title>
            <feather-icon icon="FileTextIcon" />
            <span>{{ $t("breadcrumb.yearEndReview") }}</span>
          </template>
          <year-end-review></year-end-review>
        </b-tab>
        <b-tab>
          <template #title>
            <feather-icon icon="InfoIcon" />
            <span>{{ $t("breadcrumb.ratingScaleAndValue") }}</span>
          </template>
          <rating-scale-and-value></rating-scale-and-value>
        </b-tab>
      </b-tabs>

      <b-modal
        ref="refModal"
        size="lg"
        :title="$t(typeTitle)"
        :okTitle="$t('button.ok')"
        ok-only
        centered
      >
        <b-alert show variant="warning" class="p-1" v-if="typeData.remark">
          <feather-icon icon="AlertTriangleIcon" class="mr-1 text-warning" />
          <label for="supervisor">{{
            $t("breadcrumb.fromDirectSupervisor")
          }}</label>
          <h6>{{ typeData.remark }}</h6>
        </b-alert>

        <b-alert show variant="warning" class="p-1" v-if="typeData.hodRemark">
          <feather-icon icon="AlertTriangleIcon" class="mr-1 text-warning" />
          <label for="hod">{{ $t("breadcrumb.fromHod") }}</label>
          <h6>{{ typeData.hodRemark }}</h6>
        </b-alert>

        <b-alert show variant="warning" class="p-1" v-if="typeData.gmRemark">
          <feather-icon icon="AlertTriangleIcon" class="mr-1 text-warning" />
          <label for="hod">{{ $t("breadcrumb.fromGm") }}</label>
          <h6>{{ typeData.gmRemark }}</h6>
        </b-alert>

        <b-alert show variant="warning" class="p-1" v-if="typeData.hrRemark">
          <feather-icon icon="AlertTriangleIcon" class="mr-1 text-warning" />
          <label for="hr">{{ $t("breadcrumb.fromHr") }}</label>
          <h6>{{ typeData.hrRemark }}</h6>
        </b-alert>
      </b-modal>
    </b-overlay>
  </div>
</template>

<script>
import {
  BCard,
  BCardTitle,
  BCardText,
  BRow,
  BCol,
  BButton,
  BAvatar,
  BImg,
  BTabs,
  BTab,
  BAlert,
  BLink,
  BModal,
  BOverlay,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import Repository from "@/repositories/RepositoryFactory";
import NButtonLoading from "@/components/NButtonLoading";
import NImageView from "@/components/NImageView.vue";
import OkrSettingReview from "./components/OkrSettingReview.vue";
import MidYearReview from "./components/MidYearReview.vue";
import YearEndReview from "./components/YearEndReview.vue";
import RatingScaleAndValue from "./components/RatingScaleAndValue.vue";
import moment from "moment";
import state from "@/data/state";
import { canHrExport } from "@/libs/helper";

const OkrSettingRepository = Repository.get("okrSetting");

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardTitle,
    BCardText,
    BButton,
    BAvatar,
    BImg,
    BTabs,
    BTab,
    BAlert,
    BLink,
    BModal,
    BOverlay,

    NButtonLoading,
    OkrSettingReview,
    MidYearReview,
    YearEndReview,
    RatingScaleAndValue,
    NImageView,
  },
  directives: {
    Ripple,
  },
  computed: {
    state() {
      return { ...state };
    },
  },
  data() {
    return {
      tabIndex: this.$route.query.tabIndex
        ? Number(this.$route.query.tabIndex)
        : 0,
      data: {},
      midYearReview: {},
      yearEndReview: {},
      typeTitle: null,
      typeData: {
        remark: null,
        hodRemark: null,
        hrRemark: null,
      },
      query: {
        page: Number(this.$route.query.page) || 1,
        order: this.$route.query.order || null,
        sort: this.$route.query.sort || null,
      },
      a: null,
      loading: false,

      canHrExport,
    };
  },
  watch: {
    tabIndex(value) {
      this.$router.replace({
        query: {
          tabIndex: value,
        },
      });
    },
  },
  created() {
    this.show();
  },
  mounted() {
    this.a = document.createElement("a");
    document.body.appendChild(this.a);
    this.a.style = "display: none";
  },
  methods: {
    show() {
      this.loading = true;
      OkrSettingRepository.show(this.$route.params.id)
        .then((response) => {
          const data = response?.data?.data;
          if (data) {
            this.data = { ...data };
            this.midYearReview = this.data?.midYearReview;
            this.yearEndReview = this.data?.yearEndReview;
          }
        })
        .then(() => {
          this.loading = false;
        });
    },
    canExport() {
      if(this.yearEndReview !== null) {
        return (
          this.state.hrapproved === this.yearEndReview.state ||
          this.state.managementapproved === this.yearEndReview.state ||
          this.state.managementrejected === this.yearEndReview.state
        );
      }
      return false;
    },
    isDisabledMidYearReview() {
      return (
        this.midYearReview === null ||
        this.midYearReview.state === this.state.draft
      );
    },
    isDisabledYearEndReview() {
      return (
        this.yearEndReview === null ||
        this.yearEndReview.state === this.state.draft
      );
    },
    openModal(type) {
      if (type == "okrsetting") {
        this.typeTitle = "breadcrumb.okrSetting";
        this.typeData = {
          remark: this.data.rejectRemark,
          hodRemark: this.data.hodRejectRemark,
          gmRemark: this.data.gmRejectRemark,
          hrRemark: this.data.hrRejectRemark,
        };
      } else if (type == "midyearreview") {
        this.typeTitle = "breadcrumb.midYearReview";
        this.typeData = {
          remark: this.data.midYearReview?.rejectRemark,
          hodRemark: this.data.midYearReview?.hodRejectRemark,
          gmRemark: this.data.midYearReview?.gmRejectRemark,
          hrRemark: this.data.midYearReview?.hrRejectRemark,
        };
      } else if (type == "yearendreview") {
        this.typeTitle = "breadcrumb.yearEndReview";
        this.typeData = {
          remark: this.data.yearEndReview?.rejectRemark,
          hodRemark: this.data.yearEndReview?.hodRejectRemark,
          gmRemark: this.data.yearEndReview?.gmRejectRemark,
          hrRemark: this.data.yearEndReview?.hrRejectRemark,
        };
      }
      this.$refs.refModal.show();
    },
    exportExcel() {
      this.loading = true;
      const params = {
        id: this.$route.params.id,
      };

      OkrSettingRepository.exportExcel(params)
        .then((response) => {
          this.loading = false;
          let blob = new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          let url = window.URL.createObjectURL(blob);
          this.a.href = url;
          this.a.download = `OKR - ${this.data.employee.name} ${moment().format(
            "DD-MMM-YYYY"
          )}.xlsx`;
          this.a.click();
          window.URL.revokeObjectURL(url);
        })
        .catch(() => {
          this.loading = false;
        });
    },
    exportPdf() {
      this.loading = true;
      const params = {
        id: this.$route.params.id,
      };

      OkrSettingRepository.exportPdf(params)
        .then((response) => {
          this.loading = false;
          let blob = new Blob([response.data], { type: "application/pdf" });
          let url = window.URL.createObjectURL(blob);
          this.a.href = url;
          this.a.download = `OKR - ${this.data.employee.name} ${moment().format(
            "DD-MMM-YYYY"
          )}.pdf`;
          this.a.click();
          window.URL.revokeObjectURL(url);
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
  setup() {
    const resource = "okr-setting";
    const route = "okr-setting";

    return { resource, route };
  },
};
</script>
