<template>
  <div>
    <b-overlay
      :show="loading"
      :style="{
        width: fullWidth ? '' : `${pw}px`,
        height: fullWidth ? '' : `${ph}px`,
      }"
    >
      <div
        class="image-uploader flex-center-vertically text-center"
        style="min-height: 80px; height: auto; border: 1px dashed"
        :style="{
          borderColor: activeColor,
          cursor: readonly ? '' : 'pointer',
          backgroundColor: readonly ? secondary : '',
          width: fullWidth ? '' : `${pw}px`,
          height: fullWidth ? '' : `${ph}px`,
        }"
        ref="dragDropBox"
        @click="chooseImage"
        @dragover="dragover"
        @dragleave="dragleave"
        @drop="drop"
      >
        <div v-if="(!uploadImage && !image) || !value">
          <div
            class="text-center"
            :style="{
              color: readonly ? '#ffffff' : activeColor,
            }"
          >
            {{ $t("general.clickOrDragDropToUploadSignature") }}
          </div>
        </div>
        <div v-else>
          <img
            class="img-fluid"
            :class="{
              'p-1': !readonly,
            }"
            :src="uploadImage ? uploadImage : image"
            alt=""
            :style="{
              height: fullWidth ? '' : `${ph}px`,
            }"
          />
        </div>
      </div>
      <input
        type="file"
        style="display: none"
        multiple
        name="fields[assetsFieldHandle][]"
        id="assetsFieldHandle"
        class="w-px h-px opacity-0 overflow-hidden absolute"
        @change="onChange"
        ref="fileInput"
        accept=".png"
      />
    </b-overlay>
  </div>
</template>

<script>
import {
  BOverlay,
  BModal,
  BCard,
  BButton,
  BRow,
  BCol,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
} from "bootstrap-vue";
import { $themeConfig } from "@themeConfig";
import Repository from "@/repositories/RepositoryFactory";
import NPagination from "@/components/NPagination";
import vSelect from "vue-select";
import Compressor from "compressorjs";

const FileRepository = Repository.get("file");

export default {
  delimiters: ["${", "}"],

  components: {
    BOverlay,
    BModal,
    BCard,
    BButton,
    BRow,
    BCol,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    vSelect,
    NPagination,
  },

  data: function () {
    return {
      loading: false,
      filelist: [],
      uploadImage: null,
    };
  },

  computed: {},

  props: {
    image: {},
    value: {},
    size: {},
    pw: {},
    ph: {},
    path: {
      type: String,
      default: "image",
    },
    fullWidth: {
      type: Boolean,
      default: true,
    },
    isDisable: {
      type: Boolean,
      default: false,
    },
    isOptional: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "Image",
    },
    readonly: {},
    uuid: {
      default: "",
    },
    compressQuality: {
      default: 0.6,
    },
    compressMineType: {
      default: "image/jpeg",
    },
  },

  watch: {},

  methods: {
    onChange() {
      if (this.readonly) {
        return;
      }

      this.filelist = [...this.$refs.fileInput.files];
      let self = this;
      if (this.filelist[0]) {
        new Compressor(this.filelist[0], {
          quality: this.compressQuality,
          mimeType: this.compressMineType,
          success(result) {
            var reader = new FileReader();
            reader.onload = (e) => {
              const file = {
                filePath: self.path,
                file: e.target.result,
                fileName: self.filelist[0].name,
                fileSize: self.filelist[0].size,
                fileType: "image",
              };
              self.upload(file);
            };
            reader.readAsDataURL(result);
          },
        });
      }
    },
    upload(file) {
      this.loading = true;
      FileRepository.upload(file)
        .then((response) => {
          const data = response?.data?.data;
          if (data) {
            this.$emit("input", data.id);
            this.$emit("change", data);
            this.uploadImage = data.fileUrl;
          }
        })
        .catch()
        .then(() => {
          this.loading = false;
        });
    },
    dragover(event) {
      if (this.readonly) {
        return;
      }

      event.preventDefault();
      this.activeColor = this.primary;
    },
    dragleave(event) {
      if (this.readonly) {
        return;
      }
      this.activeColor = this.secondary;
    },
    drop(event) {
      if (this.readonly) {
        return;
      }
      event.preventDefault();
      this.$refs.fileInput.files = event.dataTransfer.files;
      this.onChange(); // Trigger the onChange event manually
    },

    chooseImage() {
      if (this.readonly) {
        return;
      }
      this.$emit("blur", null);
      this.$refs.fileInput.value = null;
      this.$refs.fileInput.click();
    },
  },
  setup() {
    // App Name
    const { primary, secondary } = $themeConfig.color;

    let activeColor = secondary;

    return {
      primary,
      secondary,
      activeColor,
    };
  },
};
</script>

<style scoped>
.flex-center-vertically {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
</style>
