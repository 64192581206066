<template>
  <div>
    <b-card>
      <template v-if="info.yearEndReviewEn">
        <b-card-title title-tag="h5" class="font-weight-bold mb-1">
          <h5>{{ $t("breadcrumb.stepForOKRsYearEndReview") }}</h5>
        </b-card-title>
        <b-card-text class="mb-3">
          <p v-html="getTranslate(info, 'yearEndReview', $i18n.locale)"></p>
        </b-card-text>
      </template>
      <b-tabs pills class="tab-card">
        <b-tab
          v-for="(item, index) in items"
          :key="`${item}-${index}`"
          :title="`tab-${index}`"
          :tab-index="index + 1"
        >
          <!-- Tabs -->
          <template
            #title
            v-if="item.titleEn.toLowerCase().includes('comment')"
          >
            <span>{{ $t("breadcrumb.directSupervisorComment") }}</span>
          </template>
          <template
            #title
            v-else-if="item.titleEn.toLowerCase().includes('consideration')"
          >
            <span>{{ $t("breadcrumb.directSupervisorConsideration") }}</span>
          </template>
          <template
            #title
            v-else-if="item.titleEn.toLowerCase().includes('management')"
          >
            <span>{{ $t("breadcrumb.managementComment") }}</span>
          </template>
          <template
            #title
            v-else-if="item.titleEn.toLowerCase().includes('signature')"
          >
            <span>{{ $t("breadcrumb.signature") }}</span>
          </template>
          <template #title v-else>
            <span>{{ getTranslate(item, "title", $i18n.locale) }}</span>
          </template>

          <!-- Children -->
          <year-end-comment-review
            v-if="item.titleEn.toLowerCase().includes('comment')"
            :data="data"
            :items="comments"
          />
          <year-end-consider-review
            v-else-if="item.titleEn.toLowerCase().includes('consideration')"
            :data="data"
            :items="considers"
          />
          <year-end-management-comment-review
            v-else-if="item.titleEn.toLowerCase().includes('management')"
            :item="data"
            @comment="getComment"
          />
          <year-end-signature-review
            v-else-if="item.titleEn.toLowerCase().includes('signature')"
            :comment="hrComment"
          />
          <year-end-objective-review v-else :objective-type="item" />
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardTitle,
  BCardText,
  BTab,
  BTabs,
} from "bootstrap-vue";
import Repository from "@/repositories/RepositoryFactory";
import YearEndCommentReview from "./YearEndCommentReview.vue";
import YearEndConsiderReview from "./YearEndConsiderReview.vue";
import YearEndObjectiveReview from "./YearEndObjectiveReview.vue";
import YearEndSignatureReview from "./YearEndSignatureReview.vue";
import YearEndManagementCommentReview from "./YearEndManagementCommentReview.vue";
import { getTranslate } from "@/libs/helper";

const StepInformationRepository = Repository.get("stepInformation");
const ObjectiveTypeRepository = Repository.get("objectiveType");
const OkrSettingRepository = Repository.get("okrSetting");

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardTitle,
    BCardText,
    BTab,
    BTabs,

    YearEndCommentReview,
    YearEndConsiderReview,
    YearEndObjectiveReview,
    YearEndSignatureReview,
    YearEndManagementCommentReview,
  },
  computed: {},
  data() {
    return {
      data: {},
      comments: [],
      considers: [],
      info: {},
      items: [],
      hrComment: null,
      query: {
        page: Number(this.$route.query.page) || 1,
        order: "sequence_order",
        sort: "ASC",
      },
      loading: false,

      getTranslate,
    };
  },
  created() {
    this.getStepInformation();
    this.getData();
    this.show();
  },
  methods: {
    getComment(value) {
      this.hrComment = value;
    },
    getStepInformation() {
      this.loading = true;
      StepInformationRepository.show()
        .then((response) => {
          const data = response?.data?.data;
          if (data) {
            this.info = data;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    getData() {
      this.loading = true;
      const params = {
        ...this.query,
      };
      ObjectiveTypeRepository.index(params)
        .then((response) => {
          const data = response?.data?.data;
          if (data) {
            this.items = [...data.list];
            const items = [
              {
                titleEn: "comment",
                titleKm: "comment",
                sequenceOrder: this.items.length + 1,
              },
              {
                titleEn: "consideration",
                titleKm: "consideration",
                sequenceOrder: this.items.length + 2,
              },
              {
                titleEn: "management",
                titleKm: "management",
                sequenceOrder: this.items.length + 3,
              },
              {
                titleEn: "signature",
                titleKm: "signature",
                sequenceOrder: this.items.length + 4,
              },
            ];
            this.items.push(...items);
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    show() {
      this.loading = true;
      OkrSettingRepository.show(this.$route.params.id).then((response) => {
        const data = response?.data?.data;
        if (data) {
          this.data = { ...data };
          const yearEndReview = this.data?.yearEndReview;
          if (yearEndReview) {
            this.comments = [...yearEndReview.comments];
            this.considers = [...yearEndReview.considers];
          }
        }
      });
    },
  },
};
</script>
