<template>
  <div class="bg-primary p-1 rounded">
    <b-row>
      <b-col>
        <h6 class="text-white">{{ $t("field.weightedScore") }}</h6>
      </b-col>
      <b-col class="text-right">
        <h6 class="text-white">{{ convertToDouble(score) }}%</h6>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { integer } from "@/@core/utils/validations/validations";
import { BCol, BRow } from "bootstrap-vue";
import { convertToDouble } from "@/libs/helper";

export default {
  components: {
    BCol,
    BRow,
  },
  props: {
    score: {
      type: integer,
      default: 0,
    },
  },
  data() {
    return {
      convertToDouble,
    };
  },
};
</script>
