var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-overlay',{style:({
      width: _vm.fullWidth ? '' : (_vm.pw + "px"),
      height: _vm.fullWidth ? '' : (_vm.ph + "px"),
    }),attrs:{"show":_vm.loading}},[_c('div',{ref:"dragDropBox",staticClass:"image-uploader flex-center-vertically text-center",staticStyle:{"min-height":"80px","height":"auto","border":"1px dashed"},style:({
        borderColor: _vm.activeColor,
        cursor: _vm.readonly ? '' : 'pointer',
        backgroundColor: _vm.readonly ? _vm.secondary : '',
        width: _vm.fullWidth ? '' : (_vm.pw + "px"),
        height: _vm.fullWidth ? '' : (_vm.ph + "px"),
      }),on:{"click":_vm.chooseImage,"dragover":_vm.dragover,"dragleave":_vm.dragleave,"drop":_vm.drop}},[((!_vm.uploadImage && !_vm.image) || !_vm.value)?_c('div',[_c('div',{staticClass:"text-center",style:({
            color: _vm.readonly ? '#ffffff' : _vm.activeColor,
          })},[_vm._v(" "+_vm._s(_vm.$t("general.clickOrDragDropToUploadSignature"))+" ")])]):_c('div',[_c('img',{staticClass:"img-fluid",class:{
            'p-1': !_vm.readonly,
          },style:({
            height: _vm.fullWidth ? '' : (_vm.ph + "px"),
          }),attrs:{"src":_vm.uploadImage ? _vm.uploadImage : _vm.image,"alt":""}})])]),_c('input',{ref:"fileInput",staticClass:"w-px h-px opacity-0 overflow-hidden absolute",staticStyle:{"display":"none"},attrs:{"type":"file","multiple":"","name":"fields[assetsFieldHandle][]","id":"assetsFieldHandle","accept":".png"},on:{"change":_vm.onChange}})])],1)}
var staticRenderFns = []

export { render, staticRenderFns }