<template>
  <div>
    <div class="card-container mt-2">
      <b-row class="w-100">
        <b-col cols="6">
          <h6 class="pb-1">{{ $t("field.totalSelfRating") }}</h6>
          <h6>{{ $t("field.overallObjectiveScore") }}</h6>
        </b-col>
        <b-col cols="6" class="text-right">
          <h4 class="text-primary pb-1">
            {{ convertToDouble(totalSelfRating) }}%
          </h4>
          <h4 class="text-primary">
            {{ convertToDouble(overallObjectiveScore) }}%
          </h4>
        </b-col>
      </b-row>
    </div>
    <b-card-text class="mt-1" title="">
      <div class="m-0 card-headline rounded">
        <b-row align-v="center">
          <b-col>
            <h4 class="text-center">
              {{ $t("breadcrumb.overallYearEndRating") }}
            </h4>
          </b-col>
          <b-col class="text-center">
            <div
              class="p-1 rounded"
              :class="customClass"
            >
              <h4 class="text-center text-white">
                {{ convertToDouble(overallRating) }}%
              </h4>
              <hr class="bg-dark" />
              <h4 class="text-center text-white capitalize">
                {{ overall }}
              </h4>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-card-text>
  </div>
</template>

<script>
import { BCol, BRow, BCard, BCardText } from "bootstrap-vue";
import { convertToDouble } from "@/libs/helper";

export default {
  components: {
    BCol,
    BRow,
    BCard,
    BCardText,
  },
  props: {
    totalSelfRating: {},
    overallObjectiveScore: {},
    overallRating: {},
    overall: {},
    customClass: {},
  },
  data() {
    return {
      convertToDouble,
    };
  },
};
</script>
