var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('load-profile'),_c('validation-observer',{ref:"updateForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('n-form-confirmation',{key:"updateForm",attrs:{"form":_vm.$refs.updateForm,"disabled":invalid},on:{"submit":_vm.submit}},[(_vm.data.yearEndReview !== null && _vm.signatures.length > 0)?[_vm._l((_vm.signatures),function(item,index){return _c('div',{key:("signature-" + index),staticClass:"py-1"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"7","lg":"4"}},[_c('h6',{staticClass:"mb-2"},[_vm._v(" "+_vm._s(_vm.getTranslate(item.signatureLabel, "name", _vm.$i18n.locale))+" ")]),(item.date !== null)?[_c('h6',[_vm._v(" "+_vm._s(_vm.$t("field.date"))+": "+_vm._s(_vm._f("formatDate")(item.date,"LL"))+" ")])]:(!_vm.canHrSignature(item.signatureLabel.nameEn))?[_c('h6',[_vm._v(" "+_vm._s(_vm.$t("field.date"))+": "),_c('span',{staticClass:"text-grey"},[_vm._v(" N/A ")])])]:[_c('h6',[_vm._v(" "+_vm._s(_vm.$t("field.date"))+": "+_vm._s(_vm._f("formatDate")(_vm.newDate,"LL"))+" ")])]],2),_c('b-col',{attrs:{"cols":"12","md":"5","lg":"3"}},[(item.imageId !== null && item.name !== null)?[_c('b-img',{attrs:{"fluid":"","src":item.image,"alt":"Signature","height":"160","width":"160"}}),_c('h6',{staticClass:"mt-1"},[_vm._v(_vm._s(item.name))])]:(_vm.canHrSignature(item.signatureLabel.nameEn))?[_c('validation-provider',{attrs:{"vid":"signature","name":_vm.$t('field.image'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('n-async-signature-image-uploader',{staticClass:"gallery",class:errors.length > 0 ? 'is-invalid' : null,attrs:{"path":"signature","ph":"120","pw":"120","fullWidth":true},on:{"change":_vm.onChange},model:{value:(item.newImageId),callback:function ($$v) {_vm.$set(item, "newImageId", $$v)},expression:"item.newImageId"}})]}}],null,true)}),_c('validation-provider',{attrs:{"vid":"name","name":_vm.$t('field.name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"mt-1",class:errors.length > 0 ? 'is-invalid' : null,attrs:{"value":_vm.newName,"autocomplete":"off"},model:{value:(_vm.newName),callback:function ($$v) {_vm.newName=$$v},expression:"newName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})]:_vm._e()],2)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","lg":"7"}},[_c('hr',{directives:[{name:"show",rawName:"v-show",value:(index < _vm.signatures.length - 1),expression:"index < signatures.length - 1"}]})])],1)],1)}),_c('b-row',{staticClass:"pt-1"},[_c('b-col',{staticClass:"text-right",attrs:{"cols":"12"}},[(
                _vm.hasGm
                  ? _vm.canHrReview(_vm.data.yearEndReview.state)
                  : _vm.canHrReviewWithoutGm(_vm.data.yearEndReview.state)
              )?[(_vm.$can('create', _vm.resource))?_c('n-button-reject',{ref:"btnReject",attrs:{"loading":_vm.loading,"route":_vm.route},on:{"submit":function($event){return _vm.submit('reject')},"save":_vm.save,"reason":_vm.rejectRemark}}):_vm._e(),(_vm.$can('create', _vm.resource))?_c('n-button-save',{ref:"btnApprove",attrs:{"label":"button.approve","variant":"success","loading":_vm.loading,"route":_vm.route},on:{"submit":function($event){return _vm.submit('approve')},"save":_vm.save}}):_vm._e()]:_vm._e(),(_vm.canManagementReview(_vm.data.yearEndReview.state) && !_vm.loading)?[(_vm.$can('create', _vm.resource))?_c('n-button-reject',{ref:"btnManagementReject",attrs:{"label":"button.managementReject","type":"managementreject","loading":_vm.loading,"route":_vm.route},on:{"submit":function($event){return _vm.submit('managementreject')},"save":_vm.save,"reason":_vm.rejectRemarkModal}}):_vm._e(),(_vm.$can('create', _vm.resource))?_c('n-button-save',{ref:"btnManagementApprove",attrs:{"label":"button.managementApprove","variant":"success","loading":_vm.loading,"route":_vm.route},on:{"submit":function($event){return _vm.submit('managementapprove')},"save":_vm.save}}):_vm._e()]:_vm._e(),_c('n-button-loading',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-1",attrs:{"type":"button","variant":"outline-secondary","loading":_vm.loading},on:{"submit":_vm.back}},[_vm._v(" "+_vm._s(_vm.$t("button.back"))+" ")])],2)],1)]:[_c('b-img',{staticClass:"py-5",attrs:{"fluid":"","center":"","src":require("@/assets/images/pages/no-data.svg"),"height":"220","width":"220","alt":"Image"}})]],2)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }