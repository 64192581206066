<template>
  <b-button
    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
    :variant="variant"
    class="mt-1 mr-1"
    right
    split
    @click="submit()"
    :disabled="loading"
    v-bind="$attrs"
  >
    <b-spinner small label="Spinning" v-if="loading"></b-spinner>
    {{ $t(label) }}
  </b-button>
</template>

<script>
import { BSpinner, BButton } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BSpinner,
    BButton,
  },
  directives: {
    Ripple,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "button.save",
    },
    route: {
      type: String,
      default: "",
    },
    variant: {
      type: String,
      default: "primary",
    }
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    submit(type) {
      if (this.loading) {
        return;
      }

      this.$emit("submit", type);
    },
    confirm(type) {
      this.$bvModal
        .msgBoxConfirm(this.$t("alert.unableToRevertAfterSave"), {
          title: this.$t("alert.areYouSure"),
          size: "sm",
          buttonSize: "sm",
          okVariant: "primary",
          okTitle: this.$t("button.save"),
          cancelTitle: this.$t("button.cancel"),
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.$emit("save", type);
          }
        });
    },
    afterSave() {
      this.$router.push({
        name: `list-${this.route}`,
      });
    },
  },
};
</script>
