<template>
  <b-card>
    <template v-if="info.okrReviewEn">
      <b-card-title title-tag="h5" class="font-weight-bold mb-1">
        <h5>{{ $t("breadcrumb.stepForOKRsReview") }}</h5>
      </b-card-title>
      <b-card-text class="mb-3">
        <p v-html="getTranslate(info, 'okrReview', $i18n.locale)"></p>
      </b-card-text>
    </template>
    <b-tabs pills class="tab-card">
      <b-tab
        v-for="(item, index) in items"
        :key="`${item}-${index}`"
        :title="`tab-${index}`"
      >
        <template #title>
          <span>{{ getTranslate(item, "title", $i18n.locale) }}</span>
        </template>
        <objective-review
          :objective-type="item"
          :objective-type-length="items.length"
          :tab-index="index"
        ></objective-review>
      </b-tab>
    </b-tabs>
  </b-card>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardTitle,
  BCardText,
  BTab,
  BTabs,
} from "bootstrap-vue";
import Repository from "@/repositories/RepositoryFactory";
import ObjectiveReview from "./ObjectiveReview.vue";
import { getTranslate } from "@/libs/helper";

const StepInformationRepository = Repository.get("stepInformation");
const ObjectiveTypeRepository = Repository.get("objectiveType");

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardTitle,
    BCardText,
    BTab,
    BTabs,

    ObjectiveReview,
  },
  computed: {},
  data() {
    return {
      info: {},
      items: [],
      query: {
        page: Number(this.$route.query.page) || 1,
        order: "sequence_order",
        sort: "ASC",
      },
      loading: false,

      getTranslate,
    };
  },
  created() {
    this.getStepInformation();
    this.getData();
  },
  methods: {
    getStepInformation() {
      this.loading = true;
      StepInformationRepository.show()
        .then((response) => {
          const data = response?.data?.data;
          if (data) {
            this.info = data;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    getData() {
      this.loading = true;
      const params = {
        ...this.query,
      };
      ObjectiveTypeRepository.index(params)
        .then((response) => {
          const data = response?.data?.data;
          if (data) {
            this.items = [...data.list];
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>
