<template>
  <div
    class="image-uploader flex-center-vertically text-center"
    style="min-height: 120px; height: auto; border: 1px dashed"
    :style="{
      height: `${ph}px`,
      width: `${pw}px`,
    }"
  >
    <img
      v-if="image"
      class="img-fluid p-1"
      :src="image"
      style="object-fit: contain;"
      :style="{
        height: `${ph}px`,
      }"
    />
    <img
      v-else
      class="img-fluid p-1"
      src="@/assets/images/avatars/profile.jpeg"
    />
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    image: {
      type: String,
      default: "",
    },
    ph: {
      type: Number,
      default: 160,
    },
    pw: {
      type: Number,
      default: 160,
    },
  },
};
</script>

<style scoped>
.flex-center-vertically {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
</style>
