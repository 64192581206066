<template>
  <b-card>
    <template v-if="info.midYearReviewEn">
      <b-card-title title-tag="h5" class="font-weight-bold mb-1">
        <h5>{{ $t("breadcrumb.stepForOKRsMidYearReview") }}</h5>
      </b-card-title>
      <b-card-text class="mb-3">
        <p v-html="getTranslate(info, 'midYearReview', $i18n.locale)"></p>
      </b-card-text>
    </template>
    <b-tabs pills class="tab-card">
      <b-tab
        v-for="(item, index) in items"
        :key="`${item}-${index}`"
        :title="`tab-${index}`"
      >
        <!-- Tabs -->
        <template #title v-if="item.titleEn.toLowerCase().includes('comment')">
          <span>{{ $t("breadcrumb.directSupervisorComment") }}</span>
        </template>
        <template #title v-else>
          <span>{{ getTranslate(item, "title", $i18n.locale) }}</span>
        </template>

        <!-- Children -->
        <mid-year-comment-review
          v-if="item.titleEn.toLowerCase().includes('comment')"
          :item="data"
          :items="comments"
        />
        <mid-year-objective-review v-else :objective-type="item" />
      </b-tab>
    </b-tabs>
  </b-card>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardTitle,
  BCardText,
  BTab,
  BTabs,
} from "bootstrap-vue";
import Repository from "@/repositories/RepositoryFactory";
import MidYearObjectiveReview from "./MidYearObjectiveReview.vue";
import MidYearCommentReview from "./MidYearCommentReview.vue";
import { getTranslate } from "@/libs/helper";

const StepInformationRepository = Repository.get("stepInformation");
const ObjectiveTypeRepository = Repository.get("objectiveType");
const OkrSettingRepository = Repository.get("okrSetting");

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardTitle,
    BCardText,
    BTab,
    BTabs,

    MidYearObjectiveReview,
    MidYearCommentReview,
  },
  computed: {},
  data() {
    return {
      data: {},
      comments: [],
      info: {},
      items: [],
      query: {
        page: Number(this.$route.query.page) || 1,
        order: "sequence_order",
        sort: "ASC",
      },
      loading: false,

      getTranslate,
    };
  },
  created() {
    this.getStepInformation();
    this.getData();
    this.show();
  },
  methods: {
    getStepInformation() {
      this.loading = true;
      StepInformationRepository.show()
        .then((response) => {
          const data = response?.data?.data;
          if (data) {
            this.info = data;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    getData() {
      this.loading = true;
      const params = {
        ...this.query,
      };
      ObjectiveTypeRepository.index(params)
        .then((response) => {
          const data = response?.data?.data;
          if (data) {
            this.items = [...data.list];
            const comments = {
              titleEn: "comment",
              titleKm: "comment",
              sequenceOrder: this.items.length + 1,
            };
            this.items.push(comments);
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    show() {
      this.loading = true;
      OkrSettingRepository.show(this.$route.params.id).then((response) => {
        const data = response?.data?.data;
        if (data) {
          this.data = { ...data };
          const midYearReview = this.data?.midYearReview;
          if (midYearReview) {
            this.comments = [...midYearReview.comments];
          }
        }
        this.loading = false;
      });
    },
  },
};
</script>
