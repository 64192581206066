<template>
  <b-card-code title="">
    <b-card-text class="mb-3" v-if="objectiveType.descriptionEn">
      <h5>{{ getTranslate(objectiveType, "description", $i18n.locale) }}</h5>
    </b-card-text>

    <okr-setting-header />

    <template
      v-if="data.yearEndReview && data.yearEndReview.state != state.draft"
    >
      <app-collapse accordion type="margin">
        <app-collapse-item
          :is-visible="true"
          v-for="(item, index) in yearEndReview.objectives"
          :key="`${item}-${index}`"
          :title="`${item}-${index}`"
        >
          <template #header>
            <okr-setting-title
              :no="index + 1"
              :title="item.okrSettingObjective.title"
              :weight="item.okrSettingObjective.weight"
            />
          </template>
          <hr />
          <okr-setting-description>
            <template #description>
              <div
                v-for="(result, idx) in item.objectiveKeyResults"
                :key="`${result}-${idx}`"
                v-if="result.title !== null"
              >
                <hr class="my-2" v-show="idx != 0" />
                <h6>
                  <span> #{{ idx + 1 }}. </span>
                  <span>{{ result.title }}</span>
                </h6>
                <label class="mt-1 mr-2">
                  {{ $t("field.employeeReview") }}
                </label>
                <b-badge
                  v-if="result.status"
                  pill
                  :variant="getYearEndStateColor(result.status.nameEn)"
                  badge-glow
                >
                  {{ getTranslate(result.status, "name", $i18n.locale) }}
                </b-badge>
              </div>
            </template>
            <template #footer>
              <h6 class="mb-2">
                {{
                  $t("breadcrumb.employeeSelfAssessmentAndSupervisorAssessment")
                }}
              </h6>
              <div>
                <b-row>
                  <b-col cols="12" md="6" lg="4">
                    <label>{{ $t("field.selfRating") }}</label>
                  </b-col>
                  <b-col lg="4" class="d-none d-md-none d-lg-block"></b-col>
                  <b-col cols="12" md="6" lg="4">
                    <label>{{ $t("field.supervisorRating") }}</label>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12" md="6" lg="4">
                    <n-text-view
                      suffix="%"
                      :value="convertToDouble(item.rating)"
                    ></n-text-view>
                  </b-col>
                  <b-col lg="4" class="d-none d-md-none d-lg-block"></b-col>
                  <b-col cols="12" md="6" lg="4">
                    <n-text-view
                      suffix="%"
                      :value="convertToDouble(item.supervisorRating)"
                    ></n-text-view>
                  </b-col>
                </b-row>
              </div>
              <label>{{ $t("field.directSupervisorComment") }}</label>
              <n-text-view
                size="md"
                :value="item.supervisorComment"
              ></n-text-view>
              <n-weighted-score
                class="mt-1"
                :score="item.weightScore"
              ></n-weighted-score>
            </template>
          </okr-setting-description>
        </app-collapse-item>
      </app-collapse>
    </template>

    <template v-else>
      <app-collapse accordion type="margin">
        <app-collapse-item
          :is-visible="true"
          v-for="(objective, idx) in objectives"
          :key="`${objective}-${idx}`"
          :title="`${objective}-${idx}`"
        >
          <template #header>
            <okr-setting-title
              :no="idx + 1"
              :title="objective.title"
              :weight="objective.weight"
            />
          </template>
          <hr />
          <okr-setting-description>
            <template #description>
              <div
                v-for="(result, rIdx) in objective.objectiveKeyResults"
                :key="`${result}-${rIdx}`"
                v-if="result.title !== null"
              >
                <h6>
                  <span> #{{ rIdx + 1 }}. </span>
                  <span>{{ result.title }}</span>
                </h6>
                <label class="mt-1 mr-2">
                  {{ $t("field.employeeReview") }}
                </label>
                <b-badge pill variant="secondary" badge-glow>
                  {{ $t("state.notstarted") }}
                </b-badge>
                <hr
                  class="pt-2"
                  v-show="rIdx < objective.objectiveKeyResults.length - 1"
                />
              </div>
            </template>
            <template #footer>
              <h6 class="mb-2">
                {{
                  $t("breadcrumb.employeeSelfAssessmentAndSupervisorAssessment")
                }}
              </h6>
              <b-row>
                <b-col cols="4">
                  <label>{{ $t("field.selfRating") }}</label>
                  <n-text-view suffix="%" value="0.00"></n-text-view>
                </b-col>
                <b-col cols="4"></b-col>
                <b-col cols="4">
                  <label>{{ $t("field.supervisorRating") }}</label>
                  <n-text-view suffix="%" value="0.00"></n-text-view>
                </b-col>
              </b-row>
              <label>{{ $t("field.directSupervisorComment") }}</label>
              <n-text-view size="md"></n-text-view>
              <n-weighted-score class="mt-1" score="0.00"></n-weighted-score>
            </template>
          </okr-setting-description>
        </app-collapse-item>
      </app-collapse>
    </template>

    <okr-setting-footer :total-weight="totalWeight" />

    <okr-setting-total-overall-footer
      v-show="!objectiveType.titleEn.toLowerCase().includes('business')"
      :total-self-rating="yearEndReview.totalRating"
      :overall-objective-score="yearEndReview.totalWeightScore"
      :custom-class="overallStateColor"
      :overall-rating="yearEndReview.overallRating"
      :overall="
        overallStateName != null
          ? $t(`ratingScale.${overallStateName}`)
          : $t('ratingScale.unacceptable')
      "
    />
  </b-card-code>
</template>

<script>
import {
  BCol,
  BRow,
  BCard,
  BFormRadioGroup,
  BFormRadio,
  BFormGroup,
  BCardText,
  BFormInput,
  BButton,
  BFormTextarea,
  BContainer,
  BBadge,
} from "bootstrap-vue";
import {
  getTranslate,
  getYearEndStateColor,
  getYearEndOverallRatingColor,
  convertToDouble,
} from "@/libs/helper";
import BCardCode from "@core/components/b-card-code";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import OkrSettingHeader from "@/components/OkrSettingHeader.vue";
import OkrSettingTitle from "@/components/OkrSettingTitle.vue";
import OkrSettingFooter from "@/components/OkrSettingFooter.vue";
import OkrSettingTotalOverallFooter from "@/components/OkrSettingTotalOverallFooter.vue";
import OkrSettingDescription from "@/components/OkrSettingDescription.vue";
import NTextView from "@/components/NTextView.vue";
import NWeightedScore from "@/components/NWeightedScore.vue";
import Repository from "@/repositories/RepositoryFactory";
import state from "@/data/state";

const OkrSettingRepository = Repository.get("okrSetting");

export default {
  components: {
    BFormInput,
    BCard,
    BCol,
    BRow,
    BFormRadioGroup,
    BFormRadio,
    BCardText,
    BFormGroup,
    BButton,
    BFormTextarea,
    BContainer,
    BBadge,

    BCardCode,
    AppCollapse,
    AppCollapseItem,
    OkrSettingHeader,
    OkrSettingTotalOverallFooter,
    OkrSettingTitle,
    OkrSettingFooter,
    OkrSettingDescription,
    NTextView,
    NWeightedScore,
  },
  props: {
    objectiveType: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  computed: {
    state() {
      return { ...state };
    },
  },
  data() {
    return {
      data: {},
      objectives: [],
      yearEndReview: {},
      totalWeight: 0,
      overallStateName: null,
      overallStateColor: null,
      loading: false,

      getTranslate,
      getYearEndStateColor,
      getYearEndOverallRatingColor,
      convertToDouble,
    };
  },
  created() {
    this.show();
  },
  methods: {
    show() {
      this.loading = true;
      const typeId = this.objectiveType.id ?? null;
      const paramType = typeId !== null ? `?objective_type_id=${typeId}` : "";
      const params = `${this.$route.params.id}${paramType}`;
      OkrSettingRepository.show(params).then((response) => {
        const data = response?.data?.data;
        if (data) {
          this.data = { ...data };
          this.objectives = [...data?.objectives];
          this.yearEndReview = { ...data?.yearEndReview };

          this.objectives.forEach((objective) => {
            this.totalWeight += objective.weight;
          });

          if (this.yearEndReview) {
            var rating = this.yearEndReview.overallRating ?? 0;
            const state = getYearEndOverallRatingColor(rating);
            this.overallStateName = state.key;
            this.overallStateColor = state.color;
          }
        }
      });
    },
  },
};
</script>
