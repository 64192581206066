<template>
  <b-card>
    <load-profile></load-profile>
    <validation-observer ref="updateForm" #default="{ invalid }">
      <n-form-confirmation
        key="updateForm"
        @submit="submit"
        :form="$refs.updateForm"
        :disabled="invalid"
      >
        <template v-if="data.yearEndReview !== null && signatures.length > 0">
          <div
            class="py-1"
            v-for="(item, index) in signatures"
            :key="`signature-${index}`"
          >
            <b-row>
              <b-col cols="12" md="7" lg="4">
                <h6 class="mb-2">
                  {{ getTranslate(item.signatureLabel, "name", $i18n.locale) }}
                </h6>
                <template v-if="item.date !== null">
                  <h6>
                    {{ $t("field.date") }}: {{ item.date | formatDate("LL") }}
                  </h6>
                </template>
                <template
                  v-else-if="!canHrSignature(item.signatureLabel.nameEn)"
                >
                  <h6>
                    {{ $t("field.date") }}: <span class="text-grey"> N/A </span>
                  </h6>
                </template>
                <template v-else>
                  <h6>
                    {{ $t("field.date") }}: {{ newDate | formatDate("LL") }}
                  </h6>
                </template>
              </b-col>
              <b-col cols="12" md="5" lg="3">
                <template v-if="item.imageId !== null && item.name !== null">
                  <b-img
                    fluid
                    :src="item.image"
                    alt="Signature"
                    height="160"
                    width="160"
                  ></b-img>
                  <h6 class="mt-1">{{ item.name }}</h6>
                </template>
                <template
                  v-else-if="canHrSignature(item.signatureLabel.nameEn)"
                >
                  <validation-provider
                    #default="{ errors }"
                    vid="signature"
                    :name="$t('field.image')"
                    rules="required"
                  >
                    <n-async-signature-image-uploader
                      class="gallery"
                      path="signature"
                      ph="120"
                      pw="120"
                      v-model="item.newImageId"
                      :fullWidth="true"
                      :class="errors.length > 0 ? 'is-invalid' : null"
                      @change="onChange"
                    ></n-async-signature-image-uploader>
                  </validation-provider>
                  <validation-provider
                    #default="{ errors }"
                    vid="name"
                    :name="$t('field.name')"
                    rules="required"
                  >
                    <b-form-input
                      class="mt-1"
                      v-model="newName"
                      :value="newName"
                      autocomplete="off"
                      :class="errors.length > 0 ? 'is-invalid' : null"
                    ></b-form-input>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </template>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" lg="7">
                <hr v-show="index < signatures.length - 1" />
              </b-col>
            </b-row>
          </div>
          <b-row class="pt-1">
            <b-col cols="12" class="text-right">
              <template
                v-if="
                  hasGm
                    ? canHrReview(data.yearEndReview.state)
                    : canHrReviewWithoutGm(data.yearEndReview.state)
                "
              >
                <n-button-reject
                  ref="btnReject"
                  @submit="submit('reject')"
                  @save="save"
                  @reason="rejectRemark"
                  :loading="loading"
                  :route="route"
                  v-if="$can('create', resource)"
                ></n-button-reject>
                <n-button-save
                  ref="btnApprove"
                  label="button.approve"
                  variant="success"
                  @submit="submit('approve')"
                  @save="save"
                  :loading="loading"
                  :route="route"
                  v-if="$can('create', resource)"
                ></n-button-save>
              </template>
              <template
                v-if="canManagementReview(data.yearEndReview.state) && !loading"
              >
                <n-button-reject
                  ref="btnManagementReject"
                  label="button.managementReject"
                  type="managementreject"
                  @submit="submit('managementreject')"
                  @save="save"
                  @reason="rejectRemarkModal"
                  :loading="loading"
                  :route="route"
                  v-if="$can('create', resource)"
                ></n-button-reject>
                <n-button-save
                  ref="btnManagementApprove"
                  label="button.managementApprove"
                  variant="success"
                  @submit="submit('managementapprove')"
                  @save="save"
                  :loading="loading"
                  :route="route"
                  v-if="$can('create', resource)"
                ></n-button-save>
              </template>
              <n-button-loading
                type="button"
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="outline-secondary"
                :loading="loading"
                class="mt-1"
                @submit="back"
              >
                {{ $t("button.back") }}
              </n-button-loading>
            </b-col>
          </b-row>
        </template>
        <template v-else>
          <b-img
            class="py-5"
            fluid
            center
            src="@/assets/images/pages/no-data.svg"
            height="220"
            width="220"
            alt="Image"
          ></b-img>
        </template>
      </n-form-confirmation>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardTitle,
  BCardText,
  BImg,
  BFormInput,
} from "bootstrap-vue";
import {
  getTranslate,
  canHrReview,
  canManagementReview,
  canHrSignature,
  canHrReviewWithoutGm,
} from "@/libs/helper";
import Ripple from "vue-ripple-directive";
import Repository from "@/repositories/RepositoryFactory";
import NAsyncSignatureImageUploader from "@/components/NAsyncSignatureImageUploader";
import NFormConfirmation from "@/components/NFormConfirmation";
import NButtonSaveOption from "@/components/NButtonSaveOption";
import NButtonSave from "@/components/NButtonSave.vue";
import NButtonLoading from "@/components/NButtonLoading";
import NButtonReject from "@/components/NButtonReject.vue";
import NTextView from "@/components/NTextView.vue";
import { formatDate } from "@/@core/utils/filter";
import state from "@/data/state";
import moment from "moment";
import Swal from "sweetalert2";

const OkrSettingRepository = Repository.get("okrSetting");
const YearEndReviewRepository = Repository.get("yearEndReview");
const YearEndReviewSignatureRepository = Repository.get(
  "yearEndReviewSignature"
);

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardTitle,
    BCardText,
    BImg,
    BFormInput,

    NTextView,
    NFormConfirmation,
    NButtonSaveOption,
    NButtonSave,
    NButtonLoading,
    NAsyncSignatureImageUploader,
    NButtonReject,
  },
  directives: {
    Ripple,
  },
  props: {
    comment: {
      type: String,
      default: null,
    },
  },
  computed: {
    state() {
      return { ...state };
    },
  },
  watch: {
    comment: function (newValue, oldValue) {
      this.data.yearEndReview.hrComment = newValue;
    },
  },
  data() {
    return {
      data: {
        hrComment: null,
        hrRejectRemark: null,
        hrRejectBy: null,
        hrApproveBy: null,
        hrRejectAt: null,
        hrApproveAt: null,
        managementRejectRemark: null,
        managementRejectBy: null,
        managementApproveBy: null,
        managementRejectAt: null,
        managementApproveAt: null,
      },
      yearEndReview: {},
      signatures: [],
      newDate: "",
      newName: null,
      hasGm: true,
      loading: false,

      getTranslate,
      canHrReview,
      canManagementReview,
      canHrSignature,
      canHrReviewWithoutGm,
    };
  },
  created() {
    this.show();
  },
  methods: {
    show() {
      this.loading = true;
      OkrSettingRepository.show(this.$route.params.id).then((response) => {
        const data = response?.data?.data;
        if (data) {
          this.data = { ...data };
          this.yearEndReview = this.data?.yearEndReview;
          if (this.yearEndReview) {
            this.hasGm = this.yearEndReview.hodApprovedBy.managerId !== null;
            this.yearEndReview?.signatures.map((item) => {
              item.employeeId = null;
              item.newImageId = null;
            });
            this.signatures = [...this.yearEndReview.signatures];
          }
        }
        this.loading = false;
      });
    },
    onChange() {
      this.newDate = moment().format();
      this.newName = this.$store.state.profile.name;
    },
    submit(type) {
      if (type == "approve") {
        this.$refs.updateForm.validate().then((success) => {
          if (success) {
            this.$refs.btnApprove.confirm("approve");
          }
        });
      } else if (type == "reject") {
        this.$refs.btnReject.confirm("reject");
      } else if (type == "managementapprove") {
        this.$refs.updateForm.validate().then((success) => {
          if (success) {
            this.$refs.btnManagementApprove.confirm("managementapprove");
          }
        });
      } else if (type == "managementreject") {
        this.$refs.btnManagementReject.confirm("managementreject");
      }
    },
    rejectRemark(value) {
      this.data.yearEndReview.hrRejectRemark = value;
    },
    rejectRemarkModal(value) {
      this.data.yearEndReview.managementRejectRemark = value;
    },
    save(type) {
      this.loading = true;

      if (type == "approve") {
        this.data.yearEndReview.state = state.hrapproved;
        this.data.yearEndReview.hrApproveBy = this.$store.state.profile.id;
        this.data.yearEndReview.hrApproveAt = moment().format();
      } else if (type == "reject") {
        this.data.yearEndReview.state = state.hrrejected;
        this.data.yearEndReview.hrRejectBy = this.$store.state.profile.id;
        this.data.yearEndReview.hrRejectAt = moment().format();
      } else if (type == "managementapprove") {
        this.data.yearEndReview.state = state.managementapproved;
        this.data.yearEndReview.managementApproveBy =
          this.$store.state.profile.id;
        this.data.yearEndReview.managementApproveAt = moment().format();
      } else if (type == "managementreject") {
        this.data.yearEndReview.state = state.managementrejected;
        this.data.yearEndReview.managementRejectBy =
          this.$store.state.profile.id;
        this.data.yearEndReview.managementRejectAt = moment().format();
      }

      YearEndReviewRepository.updateState(
        this.data.yearEndReview.id,
        this.data.yearEndReview
      )
        .then((response) => {
          if (type == "approve") {
            this.saveSignature();
          } else {
            this.back();
          }
        })
        .catch((error) => {
          if (error.response?.status == 422) {
            var message = error.response?.data?.message;

            Swal.fire({
              title: this.$t("error.invalid"),
              icon: "warning",
              html: message,
              customClass: {
                confirmButton: "btn btn-primary",
                title: "mb-2",
                content: "p-0",
              },
              buttonsStyling: false,
            });
          }
        })
        .then(() => {
          this.loading = false;
        });
    },
    saveSignature() {
      this.loading = true;

      var signature = {};
      this.signatures.map((item) => {
        if (item.newImageId !== null && item.newName !== null) {
          item.employeeId = this.$store.state.profile.id;
          item.imageId = item.newImageId;
          item.name = this.newName;
          item.date = this.newDate;
          signature = { ...item };
        }
      });

      YearEndReviewSignatureRepository.update(signature.id, signature)
        .then((response) => {
          this.back();
        })
        .catch((error) => {
          if (error.response?.status == 422) {
            this.$refs.editForm.setErrors(error.response?.data?.message);
            var message = error.response?.data?.message;

            Swal.fire({
              title: this.$t("error.invalid"),
              icon: "warning",
              html: message,
              customClass: {
                confirmButton: "btn btn-primary",
                title: "mb-2",
                content: "p-0",
              },
              buttonsStyling: false,
            });
          }
        })
        .then(() => {
          this.loading = false;
        });
    },
    back() {
      this.$router.push({
        name: `list-${this.route}`,
      });
    },
  },
  setup() {
    const resource = "okr-setting";
    const route = "okr-setting";

    return { resource, route };
  },
};
</script>
