<template>
  <b-card>
    <load-profile></load-profile>
    <template v-if="item.midYearReview !== null && items.length > 0">
      <div class="py-2" v-for="(item, index) in items">
        <h5 class="mb-2">
          <span> {{ index + 1 }}. </span>
          <span>{{ getTranslate(item.question, "title", $i18n.locale) }}</span>
        </h5>
        <n-text-view size="md" :value="item.comment"></n-text-view>
      </div>
      <b-row class="pt-1">
        <b-col cols="12" class="text-right">
          <template
            v-if="
              item.midYearReview.hodApprovedBy.managerId !== null
                ? canHrReview(item.midYearReview.state)
                : canHrReviewWithoutGm(item.midYearReview.state)
            "
          >
            <n-button-reject
              ref="btnReject"
              @submit="submit('reject')"
              @save="save"
              @reason="rejectRemark"
              :loading="loading"
              :route="route"
              v-if="$can('create', resource)"
            ></n-button-reject>
            <n-button-save
              ref="btnApprove"
              label="button.approve"
              variant="success"
              @submit="submit('approve')"
              @save="save"
              :loading="loading"
              :route="route"
              v-if="$can('create', resource)"
            ></n-button-save>
          </template>
          <n-button-loading
            type="button"
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            :loading="loading"
            class="mt-1"
            @submit="back"
          >
            {{ $t("button.back") }}
          </n-button-loading>
        </b-col>
      </b-row>
    </template>
    <template v-else>
      <b-img
        class="py-5"
        fluid
        center
        src="@/assets/images/pages/no-data.svg"
        height="220"
        width="220"
        alt="Image"
      ></b-img>
    </template>
  </b-card>
</template>

<script>
import { BRow, BCol, BCard, BCardTitle, BCardText, BImg } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import NButtonSave from "@/components/NButtonSave.vue";
import NButtonReject from "@/components/NButtonReject.vue";
import NButtonLoading from "@/components/NButtonLoading";
import NTextView from "@/components/NTextView.vue";
import Repository from "@/repositories/RepositoryFactory";
import { getTranslate, canHrReview, canHrReviewWithoutGm } from "@/libs/helper";
import state from "@/data/state";
import moment from "moment";
import Swal from "sweetalert2";

const MidYearReviewRepository = Repository.get("midYearReview");

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardTitle,
    BCardText,
    BImg,

    NTextView,
    NButtonSave,
    NButtonReject,
    NButtonLoading,
  },
  directives: {
    Ripple,
  },
  props: {
    item: {
      type: Object,
      default: function () {
        return {};
      },
    },
    items: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  computed: {
    state() {
      return { ...state };
    },
  },
  data() {
    return {
      data: {
        hrRejectRemark: null,
        hrRejectBy: null,
        hrApproveBy: null,
        hrRejectAt: null,
        hrApproveAt: null,
      },
      loading: false,

      getTranslate,
      canHrReview,
      canHrReviewWithoutGm,
    };
  },
  mounted() {},
  methods: {
    submit(type) {
      if (type == "approve") {
        this.$refs.btnApprove.confirm("approve");
      } else {
        this.$refs.btnReject.confirm("reject");
      }
    },
    rejectRemark(value) {
      this.data.midYearReview.hrRejectRemark = value;
    },
    save(type) {
      this.loading = true;

      if (type == "approve") {
        this.data.midYearReview.state = this.state.hrapproved;
        this.data.midYearReview.hrApproveBy = this.$store.state.profile.id;
        this.data.midYearReview.hrApproveAt = moment().format();
      } else {
        this.data.midYearReview.state = this.state.hrrejected;
        this.data.midYearReview.hrRejectBy = this.$store.state.profile.id;
        this.data.midYearReview.hrRejectAt = moment().format();
      }

      MidYearReviewRepository.updateState(
        this.data.midYearReview.id,
        this.data.midYearReview
      )
        .then((response) => {
          this.back();
        })
        .catch((error) => {
          if (error.response?.status == 422) {
            var message = error.response?.data?.message;

            Swal.fire({
              title: this.$t("error.invalid"),
              icon: "warning",
              html: message,
              customClass: {
                confirmButton: "btn btn-primary",
                title: "mb-2",
                content: "p-0",
              },
              buttonsStyling: false,
            });
          }
        })
        .then(() => {
          this.loading = false;
        });
    },
    back() {
      this.$router.push({
        name: `list-${this.route}`,
      });
    },
  },
  setup() {
    const resource = "okr-setting";
    const route = "okr-setting";

    return { resource, route };
  },
};
</script>
