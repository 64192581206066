<template>
  <b-form-textarea
    v-model="value"
    :id="value"
    :placeholder="label"
    :rows="rows"
    :max-rows="maxRows"
  ></b-form-textarea>
</template>

<script>
import { integer } from "@/@core/utils/validations/validations";
import { BFormTextarea } from "bootstrap-vue";

export default {
  components: {
    BFormTextarea,
  },
  props: {
    maxRows: {
      type: integer,
      default: 6,
    },
    rows: {
      type: integer,
      default: 2,
    },
    label: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
  },
};
</script>
